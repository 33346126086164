<script>
export default {
  metaInfo: {
    title: "Received Messages (Kafka)",
  }
};
</script>

<script setup>
import DynamicReceivedMessagesKafkaView from '@/components/dynamic/ReceivedMessagesKafkaView.vue';
</script>

<template>
  <DynamicReceivedMessagesKafkaView/>
</template>

<style scoped>

</style>
